import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Grid from '../../common/Grid';
import { H1 } from '../../common/Typography';

import { minWidth, maxWidth } from '../../../utils/breakpoints';
import pseudoElement from '../../../utils/pseudoElement';
import withComplimentaryColors from '../../common/withComplimentaryColors';
import { duration, fadeInRight } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';
import fluidRange from '../../../utils/fluidRange';

import Unclip from '../../common/Unclip';

const Wrapper = styled(Grid)`
  background: none !important;
  overflow: hidden;
  ${maxWidth('tabletPortrait')} {
    padding-top: 30vw;
    --cutoff: 50px;
  }
  ${fluidRange(
    {
      prop: '--cutoff',
      fromSize: '80px',
      toSize: '100px',
    },
    'tabletPortrait',
    'desktop'
  )};
  ${fluidRange(
    {
      prop: 'padding-top',
      fromSize: '90px',
      toSize: '120px',
    },
    'tabletPortrait',
    'desktop'
  )};
  position: relative;
  &:before {
    ${pseudoElement};
    top: 0;
    right: 0;
    width: 100%;
    transition: transform ${duration}s ease;
    transform: translateX(${({ active }) => (active ? 0 : 100)}%);
    height: calc(100% - var(--cutoff));
    background: ${({ backgroundColor }) => backgroundColor};
  }
`;

const Title = styled(H1)`
  grid-row: 1;
  position: relative;
  z-index: 5;
  ${maxWidth('tabletLandscape')} {
    top: -9vw;
    grid-column: col 1 / span 12;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col 1 / span 9;
    top: 5vw;
  }
`;

const Img = styled(Unclip)`
  grid-row: 1;
  ${maxWidth('tabletPortrait')} {
    grid-column: col 2 / span 11;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col 4 / span 10;
  }
`;

function Hero({ className, backgroundColor, title, image }) {
  const [ref, visible] = useWaypoint();
  return (
    <Wrapper
      active={visible}
      ref={ref}
      backgroundColor={backgroundColor}
      className={className}
    >
      <Title css={fadeInRight(visible, 2)}>{title}</Title>
      <Img direction="right" active={visible} order={2}>
        <GatsbyImage image={image.asset.gatsbyImageData} alt={title} />
      </Img>
    </Wrapper>
  );
}

export default withComplimentaryColors(Hero);

Hero.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};
