import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import styledMap from 'styled-map';
import Grid from '../../common/Grid';

import { fadeIn, duration } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';
import fluidRange from '../../../utils/fluidRange';

const StyledButton = styled.a`
  justify-self: left;
  color: ${styledMap('color', {
    black: ({ theme }) => theme.colors.black,
    white: ({ theme }) => theme.colors.white,
  })};
  appearance: none;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${styledMap('color', {
    black: ({ theme }) => theme.colors.black,
    white: ({ theme }) => theme.colors.white,
  })};
  transition: color ${duration / 2}s ease, background ${duration / 2}s ease;
  padding: 32px 48px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  grid-column: 2 / span 12;
  ${fluidRange({
    prop: 'font-size',
    fromSize: '18px',
    toSize: '32px',
  })};
  &:hover {
    color: ${styledMap('color', {
      black: ({ theme }) => theme.colors.white,
      white: ({ theme }) => theme.colors.black,
    })};
    background: ${styledMap('color', {
      black: ({ theme }) => theme.colors.black,
      white: ({ theme }) => theme.colors.white,
    })};
  }
`;

export default function Button({ className, buttonText, buttonLink }) {
  const [ref, visible] = useWaypoint();
  const buttonProps = {
    ref,
    className,
    css: fadeIn(visible),
    href: buttonLink,
    target: '_blank',
  };
  return (
    <Grid ref={ref} className={className}>
      <StyledButton {...buttonProps}>{buttonText}</StyledButton>
    </Grid>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};
