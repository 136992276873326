import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import SplitLines from '../../common/SplitLines';
import { H3, H6 } from '../../common/Typography';

import { minWidth, maxWidth } from '../../../utils/breakpoints';
import { fadeIn, duration } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';

const Text = styled(H3)`
  position: relative;
  &:before,
  &:after {
    color: ${({ theme }) => theme.colors.greyDark};
    font-size: 1.5em;
    position: absolute;
    display: inline-block;
    transition: transform ${duration}s ease ${duration}s,
      opacity ${duration}s ease ${duration}s;
    opacity: ${({ active }) => (active ? 1 : 0)};
    transform: translateY(${({ active }) => (active ? 0 : 5)}px)
      scale(${({ active }) => (active ? 1 : 0.62)});
  }
  &:before {
    top: 0;
    content: open-quote;
    margin-left: -0.6ch;
    transform-origin: right;
  }
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col / span 10;
  }
`;

const Cite = styled.cite`
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  span {
    display: block;
    &:last-child {
      color: ${({ theme }) => theme.colors.greyDark};
    }
  }
`;

export default function Quote({ className, quote, quotee, occupation }) {
  const [ref, visible] = useWaypoint();
  return (
    <Grid ref={ref} className={className} as="blockquote">
      <Text as="p" active={visible}>
        <SplitLines visible={visible}>{quote}</SplitLines>
      </Text>
      <Cite>
        <H6 css={fadeIn(visible, 5)} as="span">
          {quotee}
        </H6>
        <H6 css={fadeIn(visible, 6)} as="span">
          {occupation}
        </H6>
      </Cite>
    </Grid>
  );
}

Quote.propTypes = {
  className: PropTypes.string,
  occupation: PropTypes.string.isRequired,
  quotee: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
};
