import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import { H4, H6 } from '../../common/Typography';

import { minWidth, maxWidth } from '../../../utils/breakpoints';
import { scrollUp } from '../../../utils/keyframes';
import pseudoElement from '../../../utils/pseudoElement';
import { fadeIn } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';

const Title = styled(H6)`
  text-align: center;
  grid-column: col / span 12;
`;

const Crew = styled.div`
  grid-column: col / span 12;
  position: relative;
  overflow: hidden;
  ${maxWidth('tabletLandscape')} {
    height: 280px;
  }
  ${minWidth('tabletLandscape')} {
    height: 400px;
  }
  &:before,
  &:after {
    ${pseudoElement};
    height: 80px;
    width: 100%;
    pointer-events: none;
    z-index: 10;
  }
  &:before {
    top: 0;
    background: ${({ theme }) =>
      `linear-gradient(${theme.colors.black} 0%, rgba(0, 0, 0, 0) 100%)`};
  }
  &:after {
    bottom: 0;
    background: ${({ theme }) =>
      `linear-gradient(rgba(0, 0, 0, 0) 0%, ${theme.colors.black} 80%, ${theme.colors.black} 100%)`};
  }
`;

const List = styled.dl`
  display: grid;
  grid-column-gap: 6%;
  position: absolute;
  align-items: center;
  width: 100%;
  animation: ${scrollUp} ${({ scrollSpeed }) => scrollSpeed}s linear infinite;
  &:nth-child(2) {
    animation-delay: -${({ scrollSpeed }) => scrollSpeed / 2}s;
  }
  ${maxWidth('tabletLandscape')} {
    grid-template-columns: 1fr;
    text-align: center;
    grid-row-gap: 6px;
    padding: 3px 0;
  }
  ${minWidth('tabletLandscape')} {
    grid-row-gap: 12px;
    grid-template-columns: 1fr 1fr;
    padding: 6px 0;
  }
`;

const Role = styled(H4)`
  ${maxWidth('tabletLandscape')} {
    font-size: 1.2em;
    color: ${({ theme }) => theme.colors.greyDark};
  }
  ${minWidth('tabletLandscape')} {
    grid-template-columns: 1fr 1fr;
    line-height: 1.5em;
    text-align: right;
  }
`;

const Name = styled(H4)`
  ${maxWidth('tabletLandscape')} {
    font-size: 1.6em;
    margin-bottom: 25px;
  }
`;

export default function Credits({ className, crew, title }) {
  const [ref, visible] = useWaypoint();

  const scrollSpeed = 15 * crew.length;
  return (
    <Grid ref={ref} className={className}>
      <Title as="h2" css={fadeIn(visible)}>
        {title}
      </Title>
      <Crew css={fadeIn(visible)}>
        {[...Array(2)].map((_, i) => (
          <List key={i} scrollSpeed={scrollSpeed}>
            {[].concat(...Array(2).fill(crew)).map((member, memberKey) => (
              <Fragment key={memberKey}>
                <Role as="dd">{member.role}</Role>
                <Name as="dt">{member.name}</Name>
              </Fragment>
            ))}
          </List>
        ))}
      </Crew>
    </Grid>
  );
}

Credits.propTypes = {
  className: PropTypes.string,
  crew: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
