import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { duration } from '../../utils/transitions';

const A = styled.a`
  color: ${({ theme }) => theme.colors.white};
  border-bottom: 2px solid transparent;
  transition: ${duration / 2}s border-color ease;
  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }
`;

export default function Link({ className, mark, children }) {
  return (
    <A className={className} href={mark.href}>
      {children}
    </A>
  );
}

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  mark: PropTypes.object.isRequired,
};
