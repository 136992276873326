import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import VideoPlayer from '../../common/VideoPlayer/index';

import { fadeIn } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';

const VideoWrapper = styled.div`
  grid-column: col / span 12;
`;

// eslint-disable-next-line no-unused-vars
export default function Video({ className, url }) {
  const [videoRef, videoVisible] = useWaypoint();
  return (
    <Grid ref={videoRef} className={className}>
      <VideoWrapper css={fadeIn(videoVisible)}>
        <VideoPlayer videoId={url} autopause resetOnEnd />
      </VideoWrapper>
    </Grid>
  );
}

Video.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
};
