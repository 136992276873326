import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SanityBlockContent from '@sanity/block-content-to-react';
import Gallery from '../pages/case-study/Gallery';
import Quote from '../pages/case-study/Quote';
import Button from '../pages/case-study/Button';
import SetPiece from '../pages/case-study/SetPiece';
import Text from '../pages/case-study/Text';
import Video from '../pages/case-study/Video';

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
};

/* eslint-disable react/prop-types */
const serializers = {
  container: Fragment,
  types: {
    gallery({ node }) {
      return <Gallery images={node.images} />;
    },
    quote({ node }) {
      return (
        <Quote
          quote={node.quote}
          quotee={node.quotee}
          occupation={node.occupation}
        />
      );
    },
    setPiece({ node }) {
      return (
        <SetPiece
          title={node.headline}
          text={node.text}
          backgroundColor={node.color.hex}
          lottie={node.lottie}
        />
      );
    },
    button({ node }) {
      return (
        <Button buttonText={node.buttonText} buttonLink={node.buttonLink} />
      );
    },
    textBlock({ node }) {
      return <Text text={node.richText} />;
    },
    video({ node }) {
      return <Video title={node.title} video={node.video} url={node.url} />;
    },
  },
};

export default function BlockContent({
  className,
  blocks,
  renderContainerOnSingleChild,
}) {
  return (
    <SanityBlockContent
      className={className}
      blocks={blocks || []}
      projectId={sanityConfig.projectId}
      dataset={sanityConfig.dataset}
      serializers={serializers}
      renderContainerOnSingleChild={renderContainerOnSingleChild}
    />
  );
}

BlockContent.propTypes = {
  className: PropTypes.string,
  blocks: PropTypes.array,
  renderContainerOnSingleChild: PropTypes.bool,
};
