import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import RichText from '../../common/RichText';

import { fadeIn } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';
import { minWidth, maxWidth } from '../../../utils/breakpoints';
import fluidRange from '../../../utils/fluidRange';

const Inner = styled.div`
  display: grid;
  ${fluidRange({
    prop: 'grid-row-gap',
    fromSize: '12.5px',
    toSize: '25px',
  })};
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col / span 9;
  }
`;

// eslint-disable-next-line no-unused-vars
export default function Text({ className, text }) {
  const [textRef, textVisible] = useWaypoint();
  return (
    <Grid ref={textRef} className={className}>
      <Inner css={fadeIn(textVisible)}>
        <RichText blocks={text} />
      </Inner>
    </Grid>
  );
}

Text.propTypes = {
  className: PropTypes.string,
  text: PropTypes.array.isRequired,
};
