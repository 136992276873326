import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useScrollPercentage } from 'react-scroll-percentage';

import useInterval from '../../hooks/useInterval';
import Animation from './Animation';

const Outer = styled.div`
  overflow: hidden;
`;

export default function ScrollAnimation({ animation }) {
  const [ref, percentage] = useScrollPercentage();
  const [percent, setPercent] = useState(0);
  const [newPercent, setNewPercent] = useState(1);

  useEffect(() => {
    setPercent(percentage);
  }, [percentage]);

  useInterval(() => {
    const diff = percent - newPercent;
    const blendFactor = 0.2;
    setNewPercent((val) => val + diff * blendFactor);
  }, 9);

  return (
    <Outer ref={ref}>
      <Animation animation={animation} position={newPercent} />
    </Outer>
  );
}

ScrollAnimation.propTypes = {
  animation: PropTypes.object.isRequired,
};
