import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SanityBlockContent from '@sanity/block-content-to-react';
import Link from './Link';
import { H4, H5 } from './Typography';

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
};

const BlockRenderer = (props) => {
  const { style } = props.node;

  if (style === 'h2') {
    return <H4 as="h2">{props.children}</H4>;
  }

  if (style === 'normal') {
    return (
      <H5
        as="p"
        css={`
          color: ${({ theme }) => theme.colors.greyLight};
        `}
      >
        {props.children}
      </H5>
    );
  }

  // Fall back to default handling
  return SanityBlockContent.defaultSerializers.types.block(props);
};

export default function RichText({ className, blocks }) {
  return (
    <SanityBlockContent
      className={className}
      blocks={blocks}
      projectId={sanityConfig.projectId}
      dataset={sanityConfig.dataset}
      serializers={{
        container: Fragment,
        types: { block: BlockRenderer },
        marks: { link: Link },
      }}
      renderContainerOnSingleChild
    />
  );
}

RichText.propTypes = {
  className: PropTypes.string,
  blocks: PropTypes.array,
};
