import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import { clamp } from '../../utils/numbers';

export default function Animation({
  animation,
  renderer = 'svg',
  loop = false,
  autoplay = false,
  position = 0,
}) {
  const animContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animContainer.current,
      renderer,
      loop,
      autoplay,
      animationData: animation,
      rendererSettings: {
        progressiveLoad: true,
      },
    });

    const closestFrame = Math.floor(position * anim.totalFrames);
    const frame = clamp(closestFrame, 0, anim.totalFrames - 2);
    anim.goToAndStop(frame, true);

    return () => anim.destroy();
  }, [position, animContainer, autoplay, loop, animation, renderer]);

  return <div ref={animContainer} />;
}

Animation.propTypes = {
  animation: PropTypes.object.isRequired,
  renderer: PropTypes.string,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  position: PropTypes.number,
};
