import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import FadeIn from '../../common/FadeIn';

import VerticalPan from '../../common/VerticalPan';
import { isEven } from '../../../utils/numbers';
import useWaypoint from '../../../hooks/useWaypoint';
import { maxWidth, minWidth } from '../../../utils/breakpoints';

// Handle special layouts otherwise return a span of 12.
const getMobileSpan = (index, total) => {
  if (total === 1) return `full-bleed`;
  return `col / span 12`;
};

const getDesktopSpan = (index, total) => {
  // Special layouts
  if (total === 1) return `full-bleed`;
  if (!isEven(total) && index === 3) return `col / span 12`;
  if (total === 5 && index === 4) return `col / span 6`;
  if (total === 5 && index === 5) return `span 6`;
  // All other layouts
  if (!isEven(index)) {
    return `col / span 6`;
  }
  return `span 6`;
};

const getDesktopHeight = (index, total) => {
  let paddingBottom = 0;
  let height = 'auto';
  if (total === 1) {
    // height = '100%';
  } else if (!isEven(total) && index === 3) {
    height = 0;
    paddingBottom = 36.428;
  } else {
    height = '100%';
  }
  return css`
    height: ${height};
    padding-bottom: ${paddingBottom}%;
  `;
};

const getOrder = (index, total) => {
  if (isEven(index) && isEven(total)) {
    return 1;
  }
  if (!isEven(total)) {
    if (index === 1 || index === 4) {
      return 1;
    }
  }
  return 1;
};

const Wrapper = styled.div`
  ${maxWidth('tabletPortrait')} {
    grid-column: ${({ index, total }) => getMobileSpan(index, total)};
  }
  ${minWidth('tabletPortrait')} {
    grid-column: ${({ index, total }) => getDesktopSpan(index, total)};
    ${({ index, total }) => getDesktopHeight(index, total)};
  }
  ${minWidth('desktop')} {
    grid-column: ${({ index, total }) =>
      total === 1 ? 'col / span 12' : getDesktopSpan(index, total)};
  }
`;

const Img = styled(GatsbyImage)`
  ${maxWidth('tabletPortrait')} {
    ${({ index, total }) => getDesktopHeight(index, total)};
  }
  ${minWidth('tabletPortrait')} {
    ${({ index, total }) => getDesktopHeight(index, total)};
  }
`;

const PanWrapper = styled(VerticalPan)`
  ${maxWidth('tabletPortrait')} {
    padding-bottom: 60%;
  }
  ${minWidth('tabletPortrait')} {
    padding-bottom: 50%;
  }
`;

export default function GalleryImage({ className, index, total, image, alt }) {
  const [ref, visible] = useWaypoint();
  return (
    <Wrapper ref={ref} className={className} index={index} total={total}>
      {total > 1 ? (
        <FadeIn active={visible} order={getOrder(index, total)}>
          <Img image={image} alt={alt} index={index} total={total} />
        </FadeIn>
      ) : (
        <PanWrapper>
          <Img
            index={index}
            total={total}
            image={image}
            alt={alt}
            direction="down"
          />
        </PanWrapper>
      )}
    </Wrapper>
  );
}

GalleryImage.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
};
