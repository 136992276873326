import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/common/Seo';
import BlockContent from '../components/common/BlockContent';
import PreFooter from '../components/common/PreFooter';
import localize from '../components/common/localize';
import Hero from '../components/pages/case-study/Hero';
import Introduction from '../components/pages/case-study/Introduction';
import Credits from '../components/pages/case-study/Credits';
import NextCaseStudy from '../components/pages/case-study/NextCaseStudy';
import Blocks from '../components/pages/case-study/Blocks';

function getNextCaseStudy(arr, id) {
  const currentIndex = arr.findIndex((item) => item.id === id);
  const nextItem = arr[currentIndex + 1] || arr[0];
  return nextItem;
}

function caseStudy({ data, pageContext }) {
  const { page, settings, caseStudies } = data;
  const { id } = pageContext;
  const nextCaseStudy = getNextCaseStudy(caseStudies.items, id);
  return (
    <>
      <SEO
        title={page.name}
        image={page.openGraphImage.asset.gatsbyImageData.src}
        description={page.title}
      />
      <Hero
        title={page.name}
        backgroundColor={page.color.hex}
        image={page.heroImage}
      />
      <Introduction
        title="Team"
        crew={[page.credits]}
        client={page.client}
        clientHeading={settings.clientHeading}
        categoryHeading={settings.categoryHeading}
        category={page.category.name}
        firstParagraph={page.introductionParagraph1}
        secondParagraph={page.introductionParagraph2}
      />
      <Blocks>
        <BlockContent blocks={page.panels} />
        {page.credits.length > 0 && (
          <Credits title={settings.teamHeading} crew={page.credits} />
        )}
      </Blocks>
      <NextCaseStudy
        backgroundColor={nextCaseStudy.color.hex}
        image={nextCaseStudy.image}
        name={nextCaseStudy.name}
        slug={nextCaseStudy.slug.current}
      />
      <PreFooter />
    </>
  );
}

export default localize(caseStudy);

export const query = graphql`
  query ($id: String!) {
    page: sanityCaseStudy(id: { eq: $id }) {
      id
      name
      client
      color {
        hex
      }
      title {
        localized
      }
      category {
        name {
          localized
        }
      }
      openGraphImage: image {
        asset {
          gatsbyImageData(layout: FIXED, width: 1200, height: 630)
        }
      }
      heroImage: image {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1230
            height: 738
            placeholder: BLURRED
          )
        }
      }
      introductionParagraph1 {
        localized
      }
      introductionParagraph2 {
        localized
      }
      panels {
        ... on SanityGallery {
          _type
          images {
            alt {
              localized
            }
            image {
              asset {
                gatsbyImageData(layout: FULL_WIDTH, width: 1440, height: 1080)
              }
            }
          }
        }
        ... on SanitySetPiece {
          _type
          color {
            hex
          }
          headline: title {
            localized
          }
          lottie {
            json
            images {
              asset {
                url
                originalFilename
              }
            }
          }
          text {
            localized
          }
        }
        ... on SanityQuote {
          _type
          quote {
            localized
          }
          quotee
          occupation {
            localized
          }
        }
        ... on SanityButton {
          _type
          buttonText {
            localized
          }
          buttonLink
        }
        ... on SanityVideo {
          _type
          title
          url
        }
        ... on SanityTextBlock {
          _type
          richText {
            localized
          }
        }
      }
      credits {
        _key
        name
        role {
          localized
        }
      }
    }
    settings: sanityCaseStudySettings {
      teamHeading {
        localized
      }
      categoryHeading {
        localized
      }
      clientHeading {
        localized
      }
    }
    caseStudies: sanityProjects {
      items: caseStudies {
        name
        id
        color {
          hex
        }
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 960, height: 570)
          }
        }
      }
    }
  }
`;

caseStudy.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};
