import React from 'react';
import PropTypes from 'prop-types';

import Grid from '../../common/Grid';

import GalleryImage from './GalleryImage';

export default function Gallery({ className, images }) {
  return (
    <Grid className={className}>
      {images.map((image, i) => (
        <GalleryImage
          key={i}
          index={i + 1}
          total={images.length}
          image={image.image.asset.gatsbyImageData}
          alt={image.alt}
        />
      ))}
    </Grid>
  );
}

Gallery.propTypes = {
  className: PropTypes.string,
  images: PropTypes.array.isRequired,
};
