import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import { H2, H5 } from '../../common/Typography';

import { maxWidth, minWidth } from '../../../utils/breakpoints';
import withComplimentaryColors from '../../common/withComplimentaryColors';
import { fadeIn } from '../../../utils/transitions';
import splitUrl from '../../../utils/splitUrl';
import useWaypoint from '../../../hooks/useWaypoint';

import ScrollAnimation from '../../common/ScrollAnimation';

const Wrapper = styled(Grid)`
  position: relative;
  align-items: start;
  align-items: center;
  overflow: hidden;
`;

const Headline = styled(H2)`
  z-index: 1;
  grid-row: 1;
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 9;
  }
  ${minWidth('tabletPortrait')} {
    position: relative;
    grid-column: col / span 7;
  }
`;

const Text = styled(H5)`
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col / span 6;
    grid-row: 2;
  }
`;

const Lottie = styled.div`
  ${maxWidth('tabletPortrait')} {
    grid-row: 1 / span 1;
    grid-column: col 6 / span 7;
  }
  ${minWidth('tabletPortrait')} {
    grid-row: 1 / span 2;
    grid-column: col 7 / span 6;
    object-fit: 100%;
    height: 100%;
    width: 100%;
  }
`;

function SetPiece({ className, backgroundColor, text, title, lottie }) {
  const [ref, visible] = useWaypoint({ threshold: 0.25 });

  const json = JSON.parse(lottie.json);
  const urlQueries = `?q=80&w=750&h=750&dpr=2&auto=format`;

  const lottieJSON = {
    ...json,
    assets: json.assets.map((asset) => {
      const match = lottie.images.filter(
        (imgAsset) => imgAsset.asset.originalFilename === asset.p
      );
      const item = match[0];
      if (item) {
        const { prefix, imageUrl } = splitUrl(item.asset.url, 'production/');
        return {
          ...asset,
          u: prefix,
          p: `${imageUrl}${urlQueries}`,
        };
      }
      return asset;
    }),
  };

  return (
    <Wrapper
      ref={ref}
      paddedTop={backgroundColor !== '#000000'}
      paddedBottom={backgroundColor !== '#000000'}
      className={className}
      backgroundColor={backgroundColor}
    >
      <Headline css={fadeIn(visible)} as="h2">
        {title}
      </Headline>
      <Text css={fadeIn(visible, 2)} as="p">
        {text}
      </Text>
      <Lottie>
        <ScrollAnimation animation={lottieJSON} scrollOffset={1} />
      </Lottie>
    </Wrapper>
  );
}

export default withComplimentaryColors(SetPiece);

SetPiece.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  lottie: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
