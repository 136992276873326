import styled from 'styled-components';

import fluidRange from '../../../utils/fluidRange';

const properties = ['grid-gap', 'padding-top', 'padding-bottom'];

export default styled.div`
  display: grid;
  ${properties.map((property) =>
    fluidRange({
      prop: property,
      fromSize: '50px',
      toSize: '160px',
    })
  )}
`;
