import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import { H4, H5, H6, P } from '../../common/Typography';

import { minWidth, maxWidth } from '../../../utils/breakpoints';
import { fadeIn } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';

const Meta = styled.aside`
  display: grid;
  grid-column: col / span 12;
  grid-column-gap: inherit;
  grid-row-gap: inherit;
  ${maxWidth('mobile')} {
    grid-template-columns: 1fr 1fr;
  }
  ${maxWidth('footerTablet')} {
    grid-row: 2;
  }
  ${minWidth('footerTablet')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${minWidth('tabletPortrait')} {
    grid-template-columns: 1fr;
    grid-column: col / span 3;
    align-self: start;
  }
`;

const MetaItem = styled.div`
  display: grid;
  grid-row-gap: 0.25em;
  ${H6} {
    color: ${({ theme }) => theme.colors.greyDark};
  }
`;

const Decription = styled.div`
  display: grid;
  grid-row-gap: inherit;
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col 4 / span 9;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col 4 / span 7;
  }
`;

const SecondParagraph = styled(H5)`
  color: ${({ theme }) => theme.colors.greyLight};
`;

export default function Introduction({
  category,
  className,
  client,
  clientHeading,
  categoryHeading,
  firstParagraph,
  secondParagraph,
}) {
  const [ref, visible] = useWaypoint();
  return (
    <Grid ref={ref} className={className} paddedTop>
      <Meta>
        <MetaItem css={fadeIn(visible, 2)}>
          <H6 as="h3">{clientHeading}</H6>
          <P>{client}</P>
        </MetaItem>
        <MetaItem css={fadeIn(visible, 3)}>
          <H6 as="h3">{categoryHeading}</H6>
          <P>{category}</P>
        </MetaItem>
      </Meta>
      <Decription>
        <H4 as="p" css={fadeIn(visible)}>
          {firstParagraph}
        </H4>
        <SecondParagraph as="p" css={fadeIn(visible, 2)}>
          {secondParagraph}
        </SecondParagraph>
      </Decription>
    </Grid>
  );
}

Introduction.propTypes = {
  category: PropTypes.string.isRequired,
  categoryHeading: PropTypes.string.isRequired,
  className: PropTypes.string,
  client: PropTypes.string.isRequired,
  clientHeading: PropTypes.string.isRequired,
  firstParagraph: PropTypes.string.isRequired,
  secondParagraph: PropTypes.string.isRequired,
};
