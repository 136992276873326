import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import ArrowLink from '../../common/ArrowLink';
import Grid from '../../common/Grid';
import { H2 } from '../../common/Typography';

import { minWidth, maxWidth } from '../../../utils/breakpoints';
import withComplimentaryColors from '../../common/withComplimentaryColors';
import fluidRange from '../../../utils/fluidRange';
import IntLink from '../../common/IntLink';

const Wrapper = styled(Grid)`
  position: relative;
  grid-row-gap: 0 !important;
  overflow: hidden;
`;

const Meta = styled(IntLink)`
  align-self: center;
  align-content: center;
  display: grid;
  ${fluidRange({
    prop: 'grid-row-gap',
    fromSize: '12.5px',
    toSize: '25px',
  })};
  ${fluidRange(
    {
      prop: 'height',
      fromSize: '320px',
      toSize: '560px',
    },
    'tabletPortrait',
    'laptop'
  )};
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
    padding: 40px 0;
    height: auto;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col / span 6;
  }
`;

const Title = styled(H2)`
  z-index: 5;
  ${minWidth('tabletPortrait')} {
    padding-right: 1.25vw;
  }
`;

const Img = styled(GatsbyImage)`
  ${maxWidth('tabletPortrait')} {
    display: none;
  }
  ${minWidth('tabletPortrait')} {
    position: absolute !important;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
  }
`;

function NextCaseStudy({ className, backgroundColor, name, slug, image }) {
  return (
    <Wrapper as="nav" className={className} backgroundColor={backgroundColor}>
      <Meta to={`/projects/${slug}/`}>
        <Title as="h2">{name}</Title>
        <ArrowLink>Visit next project</ArrowLink>
      </Meta>
      <Img image={image.asset.gatsbyImageData} alt={name} />
    </Wrapper>
  );
}

export default withComplimentaryColors(NextCaseStudy);

NextCaseStudy.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
};
